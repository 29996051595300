<template>
    <el-dialog title="实名认证详情" :close-on-click-modal="false" :visible="certificationVisible" @close="closeDialog">
        <el-descriptions direction="vertical" border :column="4">
            <el-descriptions-item label="用户姓名">{{ reviewInfo.userName || "--" }}</el-descriptions-item>
            <el-descriptions-item label="身份证正面照">
                <img class="img" @click="imgPreview(reviewInfo.idCardPositiveImg)" :src="resources_host + reviewInfo.idCardPositiveImg" />
            </el-descriptions-item>
            <el-descriptions-item label="身份证反面照">
                <img class="img" @click="imgPreview(reviewInfo.idCardReverseImg)" :src="resources_host + reviewInfo.idCardReverseImg" />
            </el-descriptions-item>
            <el-descriptions-item label="手持身份证照片">
                <img class="img" @click="imgPreview(reviewInfo.idCardHandImg)" :src="resources_host + reviewInfo.idCardHandImg" />
            </el-descriptions-item>
            <el-descriptions-item label="备注" :span="4">
                <el-input type="textarea" :rows="5" :readonly="reviewInfo.idCardState != 3 || userInfo.userType != 1" v-model="reviewRemark" placeholder="请输入审核备注（拒绝必填）"></el-input>
            </el-descriptions-item>
        </el-descriptions>
        <span slot="footer" v-if="reviewInfo.idCardState == 3 && userInfo.userType == 1" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="danger" @click="confirm(2)">拒绝</el-button>
            <el-button type="primary" @click="confirm(1)">通过</el-button>
        </span>
        <Vviewer ref="viewer"></Vviewer>
    </el-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import Vviewer from "@/components/Vviewer";
import { userauthenticationQuery, userauthenticationUpdate } from "@/api/user";
export default {
    components: { Vviewer },
    props: ["certificationVisible", "flowId"],
    watch: {
        certificationVisible(data) {
            if (data) {
                this.queryInfo();
            }
        },
    },
    data() {
        return {
            reviewRemark: "",
            reviewInfo: {},
            resources_host: process.env.VUE_APP_RESOURCES_HOST,
        };
    },
    computed: {
        ...mapGetters(["userInfo"]),
    },
    methods: {
        queryInfo() {
            const _this = this;
            const loading = _this.$loading({
                lock: true,
                text: "查询中,请稍后...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
                customClass: "fullScreenLoading",
            });
            let params = {
                flowId: this.flowId,
            };

            userauthenticationQuery(params)
                .then((res) => {
                    _this.reviewInfo = res.data;
                    _this.reviewRemark = res.data.reviewRemark;
                    loading.close();
                })
                .catch((err) => {
                    loading.close();
                });
        },
        confirm(type) {
            const _this = this;
            let params = {
                flowId: this.flowId,
                idCardState: type,
            };
            if (type == 2) {
                if (_this.reviewRemark == "" || !_this.reviewRemark) {
                    _this.$message({
                        message: "请输入审核备注！",
                        type: "warning",
                    });
                    return;
                } else {
                    params.reviewRemark = _this.reviewRemark;
                }
            }
            const loading = _this.$loading({
                lock: true,
                text: "提交中,请稍后...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
                customClass: "fullScreenLoading",
            });
            userauthenticationUpdate(params)
                .then(() => {
                    loading.close();
                    _this.closeDialog(true);
                })
                .catch((err) => {
                    loading.close();
                    console.log(err);
                });
        },
        imgPreview(url) {
            const _this = this;
            this.$refs.viewer.show([
                {
                    thumbnail: _this.resources_host + url,
                    source: _this.resources_host + url,
                },
            ]);
        },
        closeDialog(flag) {
            this.reviewRemark = "";
            this.$emit("certificationClose", flag);
        },
    },
};
</script>
<style lang="less" scoped>
.img {
    cursor: pointer;
    width: 100px;
    height: 80px;
}
/deep/ .hide .el-upload--picture-card {
    display: none;
}
</style>
