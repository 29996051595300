<template>
    <div class="app-container">
        <el-row>
            <el-form :inline="true" ref="queryForm" :model="queryForm" @submit.native.prevent>
                <el-form-item prop="querySelectedValue">
                    <el-input clearable placeholder="请输入内容" v-model="queryForm.querySelectedValue" class="input-with-select">
                        <el-select v-model="queryForm.querySelectedType" slot="prepend" placeholder="请选择">
                            <el-option label="姓名" value="userName"></el-option>
                            <el-option label="身份证号" value="idCard"></el-option>
                        </el-select>
                    </el-input>
                </el-form-item>
                <el-form-item prop="idCardState">
                    <el-select clearable v-model="queryForm.idCardState" placeholder="请选择认证状态">
                        <el-option label="认证通过" value="1"></el-option>
                        <el-option label="认证拒绝" value="2"></el-option>
                        <el-option label="认证中" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="dataRange">
                    <el-date-picker clearable v-model="queryForm.dataRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
                </el-form-item>
                <el-form-item class="floatRight">
                    <el-dropdown split-button type="primary" @click="fetchData" @command="handleCommand">
                        查询
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="reset">重置查询</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-form-item>
            </el-form>
        </el-row>
        <el-table v-loading="listLoading" element-loading-text="加载中" :data="list" border fit highlight-current-row>
            <el-table-column label="姓名" align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="queryUserInfo(scope.row.userCode)">{{ scope.row.userName }}</el-button>
                </template>
            </el-table-column>
            <el-table-column label="状态" align="center">
                <template slot-scope="scope">
                    <el-tag effect="dark" :type="state.AcountStateColorType[scope.row.idCardState]">{{ state.idCardState[scope.row.idCardState] }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="上传时间" align="center">
                <template slot-scope="scope">
                    <span>{{ moment(scope.row.addTime).format("YYYY-MM-DD HH:mm:ss") }}</span>
                </template>
            </el-table-column>
            <el-table-column label="审核人编号" align="center">
                <template slot-scope="scope">
                    <el-button type="text" v-if="scope.row.reviewUserCode" @click="queryUserInfo(scope.row.reviewUserCode)">{{ scope.row.reviewUserCode }}</el-button>
                    <span v-else>--</span>
                </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" label="审核备注" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.reviewRemark || "--" }}</span>
                </template>
            </el-table-column>
            <el-table-column label="审核时间" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.reviewTime ? moment(scope.row.reviewTime).format("YYYY-MM-DD HH:mm:ss") : "--" }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-button slot="reference" type="text" size="small" @click="review(scope.row.flowId)">{{ userInfo.userType == 1 && scope.row.idCardState == 3 ? "审核" : "查看" }}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="marginTop-8 floatRight" layout="total, sizes, prev, pager, next" background :current-page="queryForm.pageNum + 1" :page-size="queryForm.pageSize" :total="queryForm.totalCount" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
        <UserInfo :visible="drawerVisible" :userCode="userCode" @closeDrawer="closeDrawer" />
        <verified-review :flowId="flowId" :certificationVisible="certificationVisible" @certificationClose="certificationClose" />
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getUserauthenticationList } from "@/api/user";
import state from "@/utils/state";
import UserInfo from "@/components/UserInfo";
import moment from "moment";
import VerifiedReview from "./components/VerifiedReview";

export default {
    components: { UserInfo, VerifiedReview },
    data() {
        return {
            certificationVisible: false,
            drawerVisible: false,
            listLoading: true,
            list: [],
            moment,
            userCode: null,
            flowId: null,
            queryForm: {
                querySelectedType: "userName",
                querySelectedValue: "",
                state: "",
                dataRange: "",
                pageNum: 0,
                pageSize: 10,
                totalCount: 0,
            },
            taskState: "1",
        };
    },
    computed: {
        state() {
            return state.state;
        },
        ...mapGetters(["userInfo"]),
    },
    created() {
        this.fetchData();
    },
    methods: {
        closeDrawer() {
            this.drawerVisible = false;
            this.userCode = null;
        },
        queryUserInfo(userCode) {
            this.userCode = userCode;
            this.drawerVisible = true;
        },
        handleSizeChange(val) {
            this.queryForm.pageSize = val;
            this.fetchData();
        },
        handleCurrentChange(val) {
            this.queryForm.pageNum = val - 1;
            this.fetchData();
        },
        certificationClose(refeshFlag) {
            this.flowId = null;
            this.certificationVisible = false;
            if (refeshFlag == true) {
                this.fetchData();
            }
        },
        review(flowId) {
            this.flowId = flowId;
            this.certificationVisible = true;
        },
        handleCommand(type) {
            if (type == "reset") {
                this.$refs.queryForm.resetFields();
                this.fetchData();
            }
        },
        fetchData() {
            this.listLoading = true;
            const queryForm = this.queryForm;
            let params = {
                pageNum: queryForm.pageNum,
                pageSize: queryForm.pageSize,
            };
            if (queryForm.querySelectedValue && queryForm.querySelectedValue != "") {
                params[queryForm.querySelectedType] = queryForm.querySelectedValue;
            }
            if (queryForm.idCardState && queryForm.idCardState != "") {
                params.idCardState = queryForm.idCardState;
            }
            if (queryForm.dataRange && queryForm.dataRange != "") {
                params.maxAddTime = moment(queryForm.dataRange[1]).format("YYYY-MM-DD") + " 23:59:59";
                params.minAddTime = moment(queryForm.dataRange[0]).format("YYYY-MM-DD") + " 00:00:00";
            }
            const _this = this;
            getUserauthenticationList(params)
                .then((response) => {
                    _this.list = response.data.dataList;
                    _this.queryForm.totalCount = response.data.totalCount;
                    _this.listLoading = false;
                })
                .catch((err) => {
                    _this.listLoading = false;
                });
        },
    },
};
</script>
